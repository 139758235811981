<template>
  <div class="page page__content-wrapper">

    <div class="page__full-width">
      <page-item v-for="(item, idx) in logos" :content="item" :key="idx"></page-item>
    </div>

    <ButtonDownload
      :textButton="download.text"
      :url="download.url"
      :icons="download.icons"
      class="page__download-block"
    />

    <BottomNavBlock :prev-link="bottomNav.pre" :next-link="bottomNav.next"/>
  </div>
</template>

<script>
import BottomNavBlock from "@/components/Elements/BottomNavBlock/BottomNavBlock"
import ButtonDownload from "@/components/Elements/ButtonDownload/ButtonDownload"
import PageItem from "@/components/Elements/PageItem/PageItem"

export default {
  components: {
    PageItem,
    ButtonDownload,
    BottomNavBlock
  },

  data() {
    return {
      bottomNav: {
        pre: {
          url: "/",
          text: "Главная"
        },
        next: {
          url: "/identity/color",
          text: "Цвета"
        }
      },
      logos: [
        {
          type: "slider",
          sliderType: "ColoredSlider",
          slides: [
            {
              bgColor: "#F2F4F5",
              imgSlide: "logo-page/slides/logo-01.png",
              isChecked: true
            },
            {
              bgColor: "#FFFFFF",
              imgSlide: "logo-page/slides/logo-02.png",
              isChecked: false
            },
            {
              bgColor: "#041C36",
              imgSlide: "logo-page/slides/logo-03.png",
              isChecked: false
            },
            {
              bgColor: "#0759EA",
              imgSlide: "logo-page/slides/logo-04.png",
              isChecked: false
            }
          ],
          icons: [
            {
              url: "../download-file/01-Logo/Steor-Logo-H-AI.zip",
              icon: "icon-ai.svg"
            },
            {
              url: "../download-file/01-Logo/Steor-Logo-H-PNG.zip",
              icon: "icon-png.svg"
            }
          ]
        },
        {
          type: "image",
          title: "Элементы логотипа",
          text: "Логотип либо используется целиком, либо сокращается до знака. Текстовую часть отдельно использовать не допускается.",
          images: [
            {
              img: "logo-page/logo-01.png"
            }
          ]
        },
        {
          type: "image",
          title: "Вертикальная версия",
          text: "Используется только в случаях, когда горизонтальная версия не подходит для макета.",
          images: [
            {
              img: "logo-page/logo-02.png",
              icons: [
                {
                  url: "../download-file/01-Logo/Steor-Logo-V-AI.zip",
                  icon: "icon-ai.svg"
                },
                {
                  url: "../download-file/01-Logo/Steor-Logo-V-PNG.zip",
                  icon: "icon-png.svg"
                }
              ]
            }
          ],
        },
        {
          type: "slider",
          title: "Версии логотипа для светлого и темного фона",
          text: "Технически логотип состоит из двенадцати элементов, каждый из которых залит сплошным цветом. В зависимости от яркости фона, на котором размещается логотип, используется либо один набор из двенадцати предопределенных цветов, либо другой. Форма элементов при этом остается неизменной.",
          sliderType: "ImagesSlider",
          slides: [
            {
              imgSlide: "logo-page/slides-second/logo-01.png",
              isChecked: true
            },
            {
              imgSlide: "logo-page/slides-second/logo-02.png",
              isChecked: false
            },
            {
              imgSlide: "logo-page/slides-second/logo-03.png",
              isChecked: false
            }
          ]
        },
        {
          type: "component",
          title: "Дескриптор",
          text: "Необязательный элемент, который может добавляться к логотипу в тех случаях, когда предполагаемая аудитория не знакома с брендом.",
          component: "LogoDescriptor",
          data: [
            {
              icon: "logo-page/icon-h.svg",
              src: "logo-page/logo-h.png"
            },
            {
              icon: "logo-page/icon-v.svg",
              src: "logo-page/logo-v.png"
            }
          ]
        },
        {
          type: "image",
          title: "Охранное поле",
          text: "Минимальный запас пространства, необходимый вокруг логотипа. Этот запас также необходимо соблюдать при размещении логотипа вплотную к краям макета. Рекомендованное охранное поле сверху и снизу логотипа — 1,5 α.",
          images: [
            {
              img: "logo-page/logo-safezone.png"
            }
          ]
        },
        {
          type: "slider",
          title: "Монохром",
          text: "Монохромная версия логотипа используется только в случае физической невозможности реализовать цветную версию на носителе.",
          sliderType: "ColoredSlider",
          slides: [
            {
              bgColor: "#FFFFFF",
              imgSlide: "logo-page/slides-three/logo-w.png",
              isChecked: true
            },
            {
              bgColor: "#242B33",
              imgSlide: "logo-page/slides-three/logo-b.png",
              isChecked: false
            }
          ],
          icons: [
            {
              url: "../download-file/01-Logo/Steor-Logo-Monochrome-AI.zip",
              icon: "icon-ai.svg"
            },
            {
              url: "../download-file/01-Logo/Steor-Logo-Monochrome-PNG.zip",
              icon: "icon-png.svg"
            }
          ]
        }
      ],
      download: {
        text: "Скачать все версии",
        url: "01-Logo/Steor-Logo.zip",
        icons: [
          {
            url: "Icons/1.zip",
            icon: "ai.svg"
          },
          {
            url: "Icons/1.zip",
            icon: "png.svg"
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/var-style";
@import "../../../assets/style/text-style";
@import "../../../assets/style/page-block";
@import "../../../assets/style/buttons";

.page {
  margin-bottom: 192px;

  &__min-container {
    margin-bottom: 0;
  }

  &__link-block {
    margin-left: 23px;
  }
}

/deep/.page-item {
  &__title {
    letter-spacing: 0;
  }

  &__text {
    letter-spacing: 0;
  }

  &:nth-child(5) {
    margin-bottom: 95px;
  }
}

/deep/.slider__download-block {
  margin-top: -88px;
  .btn__nav-slider {
    margin-right: 32px;
    margin-left: 0;
  }
}

/deep/.main-btn_download {
  //letter-spacing: 0.5px;
  padding-right: 41px;
}

/deep/.image__download-block {
  .btn__nav-slider {
    margin-right: 32px;
    margin-left: 0;
  }
}
///deep/.slider-images {
//  margin-bottom: 25px;
//}

///deep/.btn__nav-slider {
//  margin-left: 0;
//  margin-right: 20px;
//}

/deep/.logo-descriptor {
  margin-bottom: -12px;
}


@media screen and (max-width: 1540px) {
  .page__content-wrapper {
    padding-left: 64px;
  }
}

@media screen and (max-width: 1456px) {
  .page {
    margin-bottom: 263px;
  }
}


@media screen and (max-width: 1320px) {
  .page__content-wrapper {
    padding-left: 0;
  }
}

@media screen and (max-width: 1216px) {
  .page__link-block.download-block {
    display: none;
  }
}

@media screen and (max-width: 1090px) {
  .page {
    margin-top: 0;
    padding-top: 18px;
  }
}

@media screen and (max-width: 896px) {
  .pages-wrap {
    .container {
      .page {
        &.page__content-wrapper {
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }
  }

  .page {
    padding-top: 48px;
    margin-bottom: 190px;

    &__full-width {
      width: 100%;
    }

    &__download-block {
      display: none;
    }
  }

  /deep/.page-item {
    &__title {
      margin-bottom: 25px;
    }

    &:nth-child(5) {
      margin-bottom: 80px;
    }
  }

  /deep/.slider-images {
    margin-bottom: 0;
  }
}


</style>
